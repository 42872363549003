const sessionTypes = {
  MIND_MAPPING: 'MIND_MAPPING',
  TRIAL_SESSIONS: 'TRIAL_SESSIONS',
  FAMILARITY_SESSIONS: 'FAMILARITY_SESSIONS',
  GAD7: 'GAD7',
  PHQ9: 'PHQ9',
  SELFCARE: 'SELFCARE'
};

const sessionTypesNaming = {
  MIND_MAPPING: 'Mind Mapping',
  TRIAL_SESSIONS: 'Trial',
  FAMILARITY_SESSIONS: 'Familiarization',
  GAD7: 'GAD7',
  PHQ9: 'PHQ9',
  SELFCARE: 'Self Care'

};

const UserLanguageEnum = ['EN_US', 'EN_IN'];

const reportMessages = {
  congratulations: 'Congratulations, we could not find any noticeable tendencies in your case and that is very creditable.',
  disclaimer: `This assessment of tendencies is powered by artificial intelligence (AI) and is for informational purposes only. It does
                    not replace professional medical advice, diagnosis, or treatment. The results should not be considered a definitive
                    diagnosis, and individuals are encouraged to consult qualified healthcare professionals for personalized evaluation and
                    guidance. For limitations and user responsibilities, please read your report`,
};

module.exports = {
  UserLanguageEnum,
  sessionTypes,
  sessionTypesNaming,
  reportMessages,
};
