import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../custom.css';
import Session from './Session';
import React, { useEffect } from 'react';
import { QuestionsService, SettingsService } from '../../services';
import { capitalizeFirstLetter, showErrorMessage } from '../../managers/utility';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import HomeCards from '../Dashboard/Events/HomeCards';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../custom.css';
import ReadyForSessionModal from './ReadyForSessionModal';
import WhatToDoModal from './WhatToDoModal';
import { sessionTypes, sessionTypesNaming } from '../../common/constants';

const App = () => {
  const navigate = useNavigate();
  const { userDetails, getUserDetails, inputMode, setViewLoginModal, sessionStarted, setSessionStarted } = useAuth();

  useEffect(() => {
    if (localStorage.getItem('isAdminLoggedIn')) navigate('/admin');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [useLLM] = useState(false);
  const [recievedSettings, setRecievedSettings] = useState(false);
  const [whatToDoModal, setWhatToDoModal] = useState(false);

  const [sessionType, setSessionType] = useState('');
  const [clickedSessionType, setClickedSessionType] = useState('');

  const [userData, setUserData] = useState({});
  const [globalSettings, setGlobalSettings] = useState({});

  useEffect(() => {
    setUserData(userDetails);
  }, [userDetails]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setSessionType('');
    setOpen(false);
  };

  const verifyJWT = async () => {
    let details = localStorage.getItem('userDetails') || '';
    if (details) {
      if (details.type && details.type === 'CORPORATE') navigate('/corporate');
    }
  };

  useEffect(() => {
    getUserDetails();
    verifyJWT();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCurrentSetting()
  }, [userDetails])

  const getCurrentSetting = async () => {
    try {
      const setting = await SettingsService.getSettings();
      if (setting && setting._id) {
        setGlobalSettings(setting);
        setRecievedSettings(true);
      }
    } catch (e) {
      if (e.error === 'Invalid token') console.log('Unauthorised');
    }
  };

  const checkLoginBeforeSession = async sessionType => {
    if (userDetails && userDetails._id) {
      // Initializing Values
      setAbleToResumeSession('NONE');
      setSessionType(sessionType);
      setClickedSessionType(sessionType);
      setLoadingSessionDetails(true);
      setShowMessage('');
      setOpen(true);
      let isSessionCompleted = false;

      console.log('current user details :', userDetails)
      if (userDetails.sessionId) {
        // Means he has an active Session
        const res = await QuestionsService.getSessionById(userDetails.sessionId);
        if (res.message === 'No Session Found') return;
        // But some how got out of sync in db (Should Not Happen)
        localStorage.setItem('sessionId', userDetails.sessionId);
        if (res.isCompleted) {
          localStorage.removeItem('sessionId');
          isSessionCompleted = true;
        } else {
          setShowMessage(
            `You already have a ${
              res && res.sessionType ? sessionTypesNaming[sessionTypes[res.sessionType]] : 'pending'
            } session running, Do you want to recontinue or start a fresh one.`
          );
          setSessionType(sessionType);
          setAbleToResumeSession('RESUME');
        }
      }
      if (!userDetails.sessionId || isSessionCompleted) {
        setAbleToResumeSession('START');
        localStorage.removeItem('sessionId');
        setShowMessage(
          `You are about to start ${capitalizeFirstLetter(
            sessionTypesNaming[sessionType]
          )} the assessment. You can pause the session any number of times in between. You can also save the session midway by clicking home button`
        );
      }
      setLoadingSessionDetails(false);
    } else {
      setViewLoginModal(true);
      showErrorMessage('Please Log in first');
    }
  };

  const [loadingSessionDetails, setLoadingSessionDetails] = useState(true);
  const [showMessage, setShowMessage] = useState(true);
  const [ableToResumeSession, setAbleToResumeSession] = useState('NONE');

  return (
    <>
      {!sessionStarted ? (
        <div id="launchContent">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-7">
                <div className="bnrheading">
                  <h2>
                    Welcome
                    <b className="fw-bold text-warning"> {userData?.name}</b> to the amazing world of
                    <b className="fw-bold text-primary"> Maya </b>
                  </h2>
                  <h6 className="text-secondary lead fs-5">
                    Maya is here to helping you uncover personalized trends and tendencies, mapping them to potential mental health
                    disorders to enhance your well-being and empower you towards a more fulfilling life.
                  </h6>

                  <div className="text-warning fw-bold" style={{ fontSize: '22px' }}>
                    <span
                      onClick={() => setWhatToDoModal(!whatToDoModal)}
                      style={{
                        border: '1px solid #616161',
                        padding: '5px 20px',
                        cursor: 'pointer',
                        borderRadius: 5,
                        backgroundColor: '#616161',
                      }}
                    >
                      What to do next ?
                    </span>
                  </div>
                  <WhatToDoModal open={whatToDoModal} handleClose={() => setWhatToDoModal(false)} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    <HomeCards checkLoginBeforeSession={checkLoginBeforeSession} />
                  </div>
                  <ReadyForSessionModal
                    setSessionType={setSessionType}
                    userDetails={userDetails}
                    ableToResumeSession={ableToResumeSession}
                    s
                    showMessage={showMessage}
                    open={open}
                    handleClose={handleClose}
                    sessionType={sessionType}
                    setSessionStarted={setSessionStarted}
                    clickedSessionType={clickedSessionType}
                    loadingSessionDetails={loadingSessionDetails}
                  />
                </div>
              </div>
              <div className="col-md-5 ms-md-auto d-flex align-items-end" style={{ position: 'relative' }}>
                <img style={{ position: 'absolute', top: '10%' }} alt="filler" src="/images/maya.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Session
          useLLM={useLLM}
          sessionType={sessionType}
          inputMode={inputMode}
          recievedSettings={recievedSettings}
          globalSettings={globalSettings}
          setSessionStarted={setSessionStarted}
          setAbleToResumeSession={setAbleToResumeSession}
          setSessionType={setSessionType}
          setLoadingSessionDetails={setLoadingSessionDetails}
          setShowMessage={setShowMessage}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export default App;
