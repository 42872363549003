import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";



const mediaModule = {
    getAllPlans,
    addPlans,
    updatePlans,
    deletePlan
};

export default mediaModule;

async function getAllPlans(requestData) {
    let getActive = (requestData && requestData.isDeleted === false) ? "?isDeleted=false" : ""
    let url = process.env.REACT_APP_API_URI + `/api/user/get-plans${getActive}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addPlans(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/add-plans/`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updatePlans(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/update-plans/${requestData._id}`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function deletePlan(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/delete-plan/${requestData}`
    return httpService(httpConstants.METHOD_TYPE.DELETE, getHeaders(), {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

