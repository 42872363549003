import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid } from '@mui/material';
import { DisorderService } from '../../../services';


const redStyle = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'red',
    margin: '10px',
    display: 'inline-block',
};

const yellowStyle = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'yellow',
    margin: '10px',
    display: 'inline-block',
};

const greenStyle = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'green',
    margin: '10px',
    display: 'inline-block',
};

const SessionReportTable = ({ detectedDisorders }) => {
    // Sample data

    const freeTrialDisorder = [
        "Dementia",
        "Schizophrenia",
        "Persistent Delusion Disorder",
        "Manic Disorder",
        "Depressive Disorder",
        "GAD7",
        "PHQ9",
        "Panic Disorder",
        "OCD",
        "ADHD",
        "Personality disorder"
    ]


    const doesBIsPresent = (string) => {
        const occurrences = detectedDisorders.filter(item => item.toLowerCase().includes(string.toLowerCase()));
        if (occurrences.length >= 2) {
            return "RED"
        } else if (occurrences.length === 1) {
            if (occurrences[0].includes("SEC"))
                return "GREEN"
            return "YELLOW"
        } else {
            return "GREEN"
        }
    }

    const [disorders, setDisorders] = useState([])

    useEffect(() => {
        getDisorders()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDisorders = async () => {
        const disorders = await DisorderService.getAllDisorderData();
        setDisorders(disorders)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow sx={{ height: "30px" }}>
                        <TableCell>Name</TableCell>
                        <TableCell>Red</TableCell>
                        <TableCell>Yellow</TableCell>
                        <TableCell>Green</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {freeTrialDisorder.map((row) => (
                        <TableRow sx={{ height: "30px", p: 0 }} key={row}>
                            <TableCell sx={{ p: "0 10px 0 10px" }}>{row}</TableCell>
                            <TableCell sx={{ p: "0 10px 0 10px" }}> {doesBIsPresent(row) === "RED" ? <div style={redStyle}></div> : ""}</TableCell>
                            <TableCell sx={{ p: "0 10px 0 10px" }}>{doesBIsPresent(row) === "YELLOW" && <div style={yellowStyle}></div>}</TableCell>
                            <TableCell sx={{ p: "0 10px 0 10px" }}>{doesBIsPresent(row) === "GREEN" && <div style={greenStyle}></div>}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Grid container sx={{ paddingTop: 5 }}>
                <Typography variant={"h5"} textAlign={"center"} sx={{ width: "100%" }}>You have not been assessed for the following</Typography>
                {disorders.map((item) => {
                    if (!freeTrialDisorder.includes(item.name))
                        return (
                            <Grid sx={{ padding: 3 }} item md={6} sm={12}>
                                <Typography>{item.name}</Typography>
                            </Grid>
                        )
                    else return <></>
                })}
            </Grid>
        </TableContainer>
    );
};

export default SessionReportTable;
