import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { PlanService } from '../../../services';
import { showErrorMessage } from '../../../managers/utility';
import { useAuth } from '../../../AuthContext';
import PromoCode from './PlansData/PromoCode';

const StyledCard = styled(Card)(({ theme, color }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '&:hover': {
    boxShadow: theme.shadows[10],
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    backgroundColor: color,
  },
}));

export default function Pricing({ open, handleClose }) {
  const { userDetails, setViewLoginModal } = useAuth();
  const [plansData, setPlansData] = useState([]);
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const [couponData, setCouponData] = useState(false);

  const getAllPlans = async () => {
    try {
      const allPlans = await PlanService.getAllPlans({ isDeleted: false });
      setPlansData(allPlans);
    } catch (error) {
      console.error('Error fetching plans:');
    }
  };

  const handleButtonClick = plan => {
    if (userDetails && userDetails._id) {
      handleClose();
      setOpenCheckOut(true);
      setCouponData(plan); // Store the selected plan in couponData
    } else {
      showErrorMessage('Please Login First');
      setViewLoginModal(true);
      handleClose();
    }
  };

  useEffect(() => {
    getAllPlans();
  }, [userDetails]);

  return (
    <>
      {open === true && (
        <Box>
          <Dialog open={handleButtonClick} maxWidth="lg" fullWidth>
            <DialogTitle>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h4">Pricing Information</Typography>
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" gutterBottom>
                Choose the plan that best fits your needs:
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                overflow="auto"
                gap={3}
                sx={{
                  '&::-webkit-scrollbar': { height: 8 },
                  '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: 4 },
                  '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
                  padding:"20px"
                }}
              >
                {plansData.map((item, index) => (
                  <StyledCard
                    color={item.color}
                    key={index}
                    sx={{
                      minWidth: 300,
                      width: 100,
                      flex: '0 0 auto',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '470px',
                      marginLeft: '1px',
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1, overflow: 'hidden' }}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                        <Box>
                          <Typography sx={{ fontWeight: 'bold' }} variant="h6" component="h3">
                            {item.plansType}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {item.duration} {item.durationType}
                          </Typography>
                        </Box>
                        <Box textAlign="right">
                          <Typography sx={{ fontWeight: 'bold' }} variant="h5" component="p">
                            ₹{item.price}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Plan
                          </Typography>
                        </Box>
                      </Box>
                      <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2" gutterBottom>
                        {item.plansTitle}
                      </Typography>
                      <List dense>
                        {item.plansDetails.map((detail, i) => (
                          <ListItem key={i} disableGutters>
                            <ListItemIcon style={{ minWidth: '20px' }}>
                              <FiberManualRecordIcon style={{ fontSize: '8px', color: item.color }} />
                            </ListItemIcon>
                            <ListItemText primary={detail} />
                          </ListItem>
                        ))}
                      </List>
                      <Typography variant="caption" align="center" display="block" mt={2}>
                        *Conversation words limit is 50 words / conversation
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button onClick={() => handleButtonClick(item)} fullWidth variant="outlined" color="primary">
                        Buy Now
                      </Button>
                    </CardActions>
                  </StyledCard>
                ))}
              </Box>
              <Box textAlign="center" mt={4}>
                <Typography variant="body2">
                  Please contact us at{' '}
                  <a href="mailto:care@aipsychi.org" style={{ color: 'inherit', textDecoration: 'underline' }}>
                    care@aipsychi.org
                  </a>{' '}
                  for a custom quote.
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      )}
      {openCheckOut === true && (
        <PromoCode
          plansData={plansData}
          setPlansData={setPlansData}
          couponData={couponData}
          setCouponData={setCouponData}
          setOpenCheckOut={setOpenCheckOut}
          openCheckOut={openCheckOut}
        />
      )}
    </>
  );
}
