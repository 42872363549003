import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';


const redStyle = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'red',
    margin: '10px',
    display: 'inline-block',
};

const yellowStyle = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'yellow',
    margin: '10px',
    display: 'inline-block',
};

const greenStyle = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'green',
    margin: '10px',
    display: 'inline-block',
};

const SessionReportTable = ({ detectedDisorders, selectedSession }) => {
    // Sample data

    const freeTrialDisorder = [
        "Dementia",
        "Schizophrenia",
        "Persistent Delusion Disorder",
        "Manic Disorder",
        "Depressive Disorder",
        "GAD7",
        "PHQ9",
        "Panic Disorder",
        "OCD",
        "ADHD",
        "Personality disorder"
    ]


    const doesBIsPresent = (string) => {
        let disorders = selectedSession ? selectedSession.final : detectedDisorders
        if(!disorders) return ""
        const occurrences = disorders.filter(item => item.toLowerCase().includes(string.toLowerCase()));
        if (occurrences.length >= 2) {
            return "RED"
        } else if (occurrences.length === 1) {
            if (occurrences[0].includes("SEC"))
                return "GREEN"
            return "YELLOW"
        } else {
            return "GREEN"
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow sx={{ height: "30px" }}>
                        <TableCell>Name</TableCell>
                        <TableCell>Red</TableCell>
                        <TableCell>Yellow</TableCell>
                        <TableCell>Green</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {freeTrialDisorder && freeTrialDisorder.map((row) => (
                        <TableRow sx={{ height: "30px", p: 0 }} key={row}>
                            <TableCell sx={{ p: "0 10px 0 10px" }}>{row}</TableCell>
                            <TableCell sx={{ p: "0 10px 0 10px" }}> {doesBIsPresent(row) === "RED" ? <div style={redStyle}></div> : ""}</TableCell>
                            <TableCell sx={{ p: "0 10px 0 10px" }}>{doesBIsPresent(row) === "YELLOW" && <div style={yellowStyle}></div>}</TableCell>
                            <TableCell sx={{ p: "0 10px 0 10px" }}>{doesBIsPresent(row) === "GREEN" && <div style={greenStyle}></div>}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            
        </TableContainer >
    );
};

export default SessionReportTable;
